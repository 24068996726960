.root {
  padding-top: calc(5rem * var(--mantine-scale));
  padding-bottom: calc(5rem * var(--mantine-scale));
}

.title {
  font-weight: 900;
  font-size: calc(2.125rem * var(--mantine-scale));
  margin-bottom: var(--mantine-spacing-md);
  font-family: Greycliff CF, var(--mantine-font-family);
}

@media (max-width: 48em) {

.title {
    font-size: calc(2rem * var(--mantine-scale))
}
  }

@media (max-width: 48em) {

.control {
    width: 100%
}
  }

@media (min-width: 48em) {

.mobileImage {
    display: none
}
  }

@media (max-width: 47.99em) {

.desktopImage {
    display: none
}
  }
