@font-face{
  font-family: "Duplet";
  src:local("Duplet-Light"),
  url('./assets/fonts/Duplet-Light.woff');
  font-weight: 300;
}
@font-face{
  font-family: "Duplet";
  src:local("Duplet-Regular"),
  url('./assets/fonts/Duplet-Regular.woff');
  font-weight: 400;
}
@font-face{
  font-family: "Duplet";
  src:local("Duplet-Semibold"),
  url('./assets/fonts/Duplet-Semibold.woff');
  font-weight: 600;
}
@font-face{
  font-family: "Duplet";
  src:local("Duplet-Bold"),
  url('./assets/fonts/Duplet-Bold.woff');
  font-weight: 700;
}

:root {
  font-family: Duplet, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.45;
  font-weight: 400;

  color-scheme: light dark;
  
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
}
