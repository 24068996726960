
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f1f5f9 !important;
  /* #f1f5f9!important */
  height: 100%;
  font-size: 13px;
  color: #2b2b2b;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparant;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.15);
  background-color: rgba(150, 150, 150, 0.25);
}
