.wrapper {
  position: relative;
  margin-bottom: calc(1.875rem * var(--mantine-scale));
}

.dropzone {
  border-width: calc(0.0625rem * var(--mantine-scale));
  padding-bottom: calc(3.125rem * var(--mantine-scale));
}

.icon {
  color: var(--mantine-color-gray-4);
}

[data-mantine-color-scheme='dark'] .icon {
  color: var(--mantine-color-dark-3);
}

.control {
  position: absolute;
  width: calc(15.625rem * var(--mantine-scale));
  left: calc(50% - calc(7.8125rem * var(--mantine-scale)));
  bottom: calc(-1.25rem * var(--mantine-scale));
}
