.tableTr {
  position: relative;
  /* text-align: center; */
}
.tableTr:hover {
  background-color: #ebf5f0;
}
.floating {
  padding: 5px;
  border-radius: 50px;
  position: fixed;
  align-items: center;
  opacity: 0.93;
  left: 0;
  right: 0;
  width: fit-content;
  margin-left: auto;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  background: #00854a;
  margin-right: auto;
}
.floatingText {
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainBoxStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.displayText {
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 0 20px;
}
.numberCount {
  font-weight: 500;
  color: white;
  font-size: 20px;
}
.actionDiv {
  position: relative;
  /* width: 40px;
  height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.actionIcon {
  /* border-radius: 35px; */
  /* height: 35px;
  width: 35px; */
  /* background: linear-gradient(270deg, #454545 -11.43%, #242424 97.14%); */
  cursor: pointer;
  position: relative;
  z-index: 99;
}
