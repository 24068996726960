.root::before {
    background-color: var(--mantine-color-white);
  }

.closeButton {
  color: var(--mantine-color-white);
}

.closeButton:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
